$color-navbar: #333333;
$color-resource-task-planned-confirmed: #d8ebcf;
$color-resource-task-emergency: #e6b0ae;
$color-resource-task-pending: #f0f0f0;

$color-box-background: #f0f0f0;
$color-background: #ffffff;

$color-text-light: #ffffff;
$color-text-dark: #000000;
$color-text-label: #2b2b2b;
$color-text-muted: #aaaaaa;

$customerColor: #4273cb;
$accentColor: #cba76d;
$actionColor: #757575;
$font-family: Montserrat, 'Helvetica Neue', sans-serif;

$color-wp-running: #d8ebcf;
$color-wp-paused: #ebcfcf;
$color-wp-completed: #cad2eb;

$color-sidebar-bg: #e5e5e5;
$color-sidebar: #757575;

$grid-bg-color: #e2e2e2;

$tablet-screen-size-width: 1024px;
$tablet-screen-size-height: 600px;

$task-state-color-open: green;
$task-state-color-planned: orange;
$task-state-color-closed: grey;

$stage-color-blue: #c8e4f6;
$stage-color-red: #f9dad9;
$stage-color-green: #97d781;

$driver-toolbar-height: 64px;

$icon-default-color: #757575;

$md-accent: (
  50: #f9f4ed,
  100: #efe5d3,
  200: #e5d3b6,
  300: #dbc199,
  400: #d3b483,
  500: #cba76d,
  600: #c69f65,
  700: #be965a,
  800: #b88c50,
  900: #ac7c3e,
  A100: #fffefc,
  A200: #ffe7c9,
  A400: #ffd096,
  A700: #ffc47d,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #000000,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);
$md-warn: (
  50: #fdf5f5,
  100: #fbe6e6,
  200: #f8d6d6,
  300: #f5c6c6,
  400: #f2b9b9,
  500: #f0adad,
  600: #eea6a6,
  700: #ec9c9c,
  800: #e99393,
  900: #e58383,
  A100: #ffffff,
  A200: #ffffff,
  A400: #ffffff,
  A700: #fff0f0,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #000000,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);
$md-primary: (
  50: #e8eef9,
  100: #c6d5ef,
  200: #a1b9e5,
  300: #7b9ddb,
  400: #5e88d3,
  500: #4273cb,
  600: #3c6bc6,
  700: #3360be,
  800: #2b56b8,
  900: #1d43ac,
  A100: #e4eaff,
  A200: #b1c3ff,
  A400: #7e9cff,
  A700: #6489ff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);
